<template>
  <div id="st-container" class="st-container">
    <SearchMultiSelect
      :selectedOptions="selected"
      :sourceOptions="subjectOptions"
      :isCheckbox="2"
      :placeholder="$t('search-tab.choose-subject')"
      @onChangeSelect="changedMultiSelection"
      :disabled="disabled"
      :theme="theme"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectSubjects',
  components: {
    SearchMultiSelect: () => import('@/components/atoms/SearchMultiSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    availableSubject: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      searchContent: 'GET_SEARCH_CONTENT',
    }),
    selected() {
      return this.searchContent.subject;
    },
    subjectOptions() {
      const { lang } = this;
      return this.availableSubject.map((item) => ({
        id: item.id,
        value: item.id,
        label: item[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`],
        list: item.subjectCodes,
      }));
    },
  },
  watch: {
    subjectOptions() {
      this.getSubjectList();
    },
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  methods: {
    getSubjectList() {
      if (!this.subjectOptions.length) return;
      const strSubject = this.query?.subjectIds || '';
      const subjectIdList = strSubject !== '' ? strSubject.split(',') : [];

      if (subjectIdList.findIndex((id) => this.subjectOptions.findIndex((subject) => String(subject.value) === String(id)) > -1) > -1) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['subject', subjectIdList.map((id) => this.subjectOptions.find((subject) => String(subject.value) === String(id)))]);
      } else {
        // this.$store.dispatch('SET_SEARCH_ITEM', ['subject', [this.subjectOptions[0]]]);
        this.$store.dispatch('SET_SEARCH_ITEM', ['subject', this.subjectOptions]);
      }
    },
    changedMultiSelection(pData) {
      this.$emit('changeSubject', pData);
    },
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}

.filter-tab form button.btn {
  height: 45px;
}
.checkbox-label {
  display: block;
}

.checkbox {
  position: absolute;
  left: 1vw;
}
.multiselect__tags {
  border-radius: 25px;
}
</style>
